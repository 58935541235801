<template>
  <v-overlay :value="active">
    <v-progress-circular
        indeterminate
        size="64"
        color="primary"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  computed: {
    active: {
      set(val) {
        this.$store.commit("progressLoading_State", val);
      },
      get() {
        return this.$store.state.progressLoading;
      },
    },
  },
};
</script>