import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import ModalAdd from './components/Modals/Add';
import ModalEdit from './components/Modals/Edit';
import ModalView from './components/Modals/View';
import ModalView2 from './components/Modals/View2';
import ModalDelete from './components/Modals/Delete';
import ModalAdd2 from './components/Modals/Add2';
import Loading from './components/Modals/Loading';
import moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps';
import can from './Helpers/Can';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueApexCharts from 'vue-apexcharts';
import Vue2Editor from "vue2-editor";
import VueMeta from 'vue-meta';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";


import './plugins/Axios';
import firebase from "firebase/compat";
//import './axios';

// Google Map
Vue.use(VueGoogleMaps, {
  load: {
    // key:'AIzaSyCgUsajH7cANXTJDpXJCT9x2n2Hj9kN0OE',
    key:'AIzaSyDFNsRJdbuB4Lk7bbzReVyrKViat4--HAs',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  }
});
Vue.use(VueLodash, { name: 'custom' , lodash: lodash });
Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
});

const firebaseConfig = {
  apiKey: "AIzaSyBZfmVs4WwDLkicbH6LNJ3qmOTRGnu2JnQ",
  authDomain: "vari-2f1aa.firebaseapp.com",
  projectId: "vari-2f1aa",
  storageBucket: "vari-2f1aa.appspot.com",
  messagingSenderId: "208921726994",
  appId: "1:208921726994:web:ad8a8f305bf238a7a248cb",
  measurementId: "G-98HGEG8FVN"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

Vue.config.productionTip = false
Vue.component('ModalAdd', ModalAdd);
Vue.component('ModalAdd2', ModalAdd2);
Vue.component('ModalEdit', ModalEdit);
Vue.component('ModalView', ModalView);
Vue.component('ModalView2', ModalView2);
Vue.component('ModalDelete', ModalDelete);
Vue.component('Loading', Loading);
Vue.use(Vue2Editor);

Vue.prototype.moment = moment;
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts);
Vue.use(VueMeta)

Vue.prototype.$can = can;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')