import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import User from './User';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modalAddState: false,
    modalEditState: false,
    modalDeleteState: false,
    modalAdd_2_State: false,
    modalView:false,
    modalView2:false,
    notificationToastState:false,
    progressLoading:false,
    message:'',
    color:'',
  },
  mutations: {
    modalAdd_State(state, value) {
      state.modalAddState = value;
    },
    modalEdit_State(state, value) {
      state.modalEditState = value;
    },
    modalDelete_State(state, value) {
      state.modalDeleteState = value;
    },
    modalAdd2_State(state, value) {
      state.modalAdd_2_State = value;
    },
    modalView_State(state, value) {
      state.modalView = value;
    },
    modalView_State2(state, value) {
      state.modalView2 = value;
    },
    progressLoading_State(state, value) {
      state.progressLoading = value;
    },

    Toast_State(state, result) {
      state.notificationToastState = result.value;
      state.message = result.msg;
      state.color = result.color;
    },
  },
  actions: {
  },
  modules: {
    User,
  }
})
