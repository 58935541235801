<template>
  <div>
    <div id="app">
      <sidebar />
    </div>
  </div>
</template>
<script>
import sidebar from "@/components/partial/sidebar_nav.vue";
export default {
  name: "App",
  components: {
    sidebar,
  },
};
</script>

<style lang="scss">
@import "../public/css/style.css";
@import "../public/css/font.css";
@import "../public/scss/main.scss";
/*\@import "../public/css/bulma.css";*/
</style>
