import Vue from 'vue';
import axios from 'axios';
import store from '../store';
// import router from '@/router/index';

Vue.prototype.baseRoot = process.env.VUE_APP_BASE_URL;
// Check Auth && Set Token To Header
if (store.getters['User/isAuth']) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
}

export const $axios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL,
    }
);
export const adminApiAxios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_ADMIN_URL,
    }
);
export const normalAxios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_URL,
    }
);



$axios.interceptors.response.use(function (response) {
        return response;
    },
    function (error) {
        // (error.response.status)
        if (error.response.status == 401) {
            // store.dispatch("destroyToken");
            // localStorage.removeItem('access_token');// Remove Item Of Localstorage...
            // localStorage.removeItem('user')     // Remove Item Of Localstorage...
            // router.push({ name: 'Login' });
        }

        if (error.response.status == 403) {
            // store.dispatch("destroyToken");
            // localStorage.removeItem('access_token');// Remove Item Of Localstorage...
            // localStorage.removeItem('user')     // Remove Item Of Localstorage...
            // router.push({ name: 'Login' });
        }
        return Promise.reject(error);
    });

// Vue.prototype.$axios = appBaseAxios;
Vue.prototype.$admin = adminApiAxios;
Vue.prototype.$axios = $axios;
Vue.prototype.$http = normalAxios;
